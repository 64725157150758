import HomeIcon from "@material-ui/icons/Home"
import CardTravelIcon from "@material-ui/icons/CardTravel"
import LocalShippingIcon from "@material-ui/icons/LocalShipping"
import AllInboxIcon from "@material-ui/icons/AllInbox"
import GetAppIcon from "@material-ui/icons/GetApp"
import PublishIcon from "@material-ui/icons/Publish"
import rsc from "@Config/resources"

const logytech = [
  {
    name: "home",
    type: "simple",
    icon: HomeIcon,
    redirect: "/dashboard",
    permanent: true,
    envs: ["dev", "uat", "prod"],
  },
  // ---
  {
    name: "orders",
    type: "collapse",
    icon: CardTravelIcon,
    permissions: [rsc.OrdersGroup, rsc.ORDER_BUTTON_GROUP_SIDEBAR],
    envs: ["dev", "uat", "prod"],
    subItems: [{
      name: "detail-orders",
      redirect: "/orders",
      permissions: [rsc.OrdersGroup, rsc.ORDER_BUTTON_GROUP_SIDEBAR],
      envs: ["dev", "uat", "prod"]
    }, {
      name: "detail-shipping-groups",
      redirect: "/shipping_groups",
      permissions: [rsc.OrdersGroup, rsc.ORDER_BUTTON_GROUP_SIDEBAR],
      envs: ["dev", "uat", "prod"]
    }],
  },
  // ---
  {
    name: "logistics",
    type: "collapse",
    icon: LocalShippingIcon,
    permissions: [rsc.LogisticGroup, rsc.LOGISTIC_BUTTON_GROUP_SIDEBAR],
    envs: ["dev", "uat", "prod"],
    subItems: [{
      name: "schedule",
      redirect: "/schedule",
      permissions: [rsc.LogisticGroup, rsc.LOGISTIC_BUTTON_GROUP_SIDEBAR],
      envs: ["dev", "uat"]
    }, {
      name: "sources",
      redirect: "/sources",
      permissions: [rsc.LogisticGroup, rsc.LOGISTIC_BUTTON_GROUP_SIDEBAR],
      envs: ["dev", "uat", "prod"]
    }, {
      name: "couriers",
      redirect: "/couriers",
      permissions: [rsc.LogisticGroup, rsc.LOGISTIC_BUTTON_GROUP_SIDEBAR],
      envs: ["dev", "uat", "prod"]
    }, {
      name: "capacity-used",
      redirect: "/use-capacity",
      permissions: [rsc.LogisticGroup, rsc.LOGISTIC_BUTTON_GROUP_SIDEBAR],
      envs: ["dev", "uat", "prod"]
    }, {
      name: "routes",
      redirect: "/routes",
      permissions: [rsc.LogisticGroup, rsc.LOGISTIC_BUTTON_GROUP_SIDEBAR],
      envs: ["dev", "uat", "prod"]
    }, {
      name: "coverage",
      redirect: "/coverage",
      permissions: [rsc.LogisticGroup, rsc.LOGISTIC_BUTTON_GROUP_SIDEBAR],
      envs: ["dev", "uat", "prod"]
    }, {
      name: "locations",
      redirect: "/locations",
      permissions: [rsc.LogisticGroup, rsc.LOGISTIC_BUTTON_GROUP_SIDEBAR],
      envs: ["dev", "uat", "prod"]
    }, {
      name: "calendar",
      redirect: "/calendar",
      permissions: [rsc.LogisticGroup, rsc.LOGISTIC_BUTTON_GROUP_SIDEBAR],
      envs: ["dev", "uat", "prod"]
    }],
  },
  // ---
  {
    name: "inventory",
    type: "collapse",
    icon: AllInboxIcon,
    permissions: [rsc.InventoryGroup, rsc.INVENTORY_BUTTON_GROUP_SIDEBAR],
    envs: ["dev", "uat", "prod"],
    subItems: [{
      name: "stock",
      redirect: "/stock",
      permissions: [rsc.InventoryGroup, rsc.INVENTORY_BUTTON_GROUP_SIDEBAR],
      envs: ["dev", "uat", "prod"]
    }, {
      name: "articles",
      redirect: "/items",
      permissions: [rsc.InventoryGroup, rsc.INVENTORY_BUTTON_GROUP_SIDEBAR],
      envs: ["dev", "uat", "prod"]
    }],
  },
  // ---
  {
    name: "massive-loads",
    type: "simple",
    icon: PublishIcon,
    redirect: "/massive_loads",
    permissions: [rsc.LoadGroup, rsc.MASSIVE_LOAD_BUTTON_SIDEBAR],
    envs: ["dev", "uat", "prod"],
  },
  // ---
  {
    name: "reports",
    type: "simple",
    icon: GetAppIcon,
    redirect: "/dowloadReports",
    permissions: [rsc.ReportGroup, rsc.REPORT_BUTTON_SIDEBAR],
    envs: [],
  },
]

export default logytech