const getOptionsFilterSources = (permissions, values) => {
  let optionFilters = [];
  (values || []).map(({resourceIds, name}) => {
    resourceIds.map(rId => {
      if (permissions[rId]) {
        optionFilters.push(name)
      }
      return false
    })
    return false
  })
  return optionFilters
}

export default getOptionsFilterSources