import React, { useEffect, useContext } from "react"
import { GlobalContext } from "@Context/context/GlobalState"
import PublishIcon from "@material-ui/icons/Publish"
import FormMassiveLoads from "@Components/FormMassiveLoads"
import useUserPermissions from "@Hooks/useUserPermissions"
import rsc from "@Config/resources"
import { useHistory } from "react-router"

import { useTranslation } from "react-i18next"
const NewMassiveLoadsScreen = () => {
  const { handleRoutingBreadcrumbs } = useContext(GlobalContext)
  const { t } = useTranslation("global")
  const { permission, checking } = useUserPermissions({
    resources: [
      rsc.CmCrudLoadRoutecoverage,
      rsc.MASSIVE_LOAD_BUTTON_CREATE_MASSIVE_LOAD,
    ],
  })
  const history = useHistory()

  useEffect(() => {
    if (!checking) {
      if (Object.values(permission).every((val) => val === false))
        return history.goBack()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checking])

  useEffect(() => {
    handleRoutingBreadcrumbs({
      titleHeader: t("new-bulk-upload"),
      routes: [
        { name: t("massive-loads"), route: "/massive_loads" },
        { name: t("new-bulk-upload"), route: "/massive_loads/new" },
      ],
      icon: <PublishIcon style={{ marginRight: 5, opacity: 0.5 }} />,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <FormMassiveLoads load={false} />
}

export default NewMassiveLoadsScreen
