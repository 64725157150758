import { axiosClientPRIORITIZATION } from "../config/axios"
import tokenAuth from "../config/token"

export async function postHighPriorityTruck(token, client, body) {
  tokenAuth(token, "PRIORITIZATION")
  const url = `${client}/prioritization/high_priority_trucks`
  let result = await axiosClientPRIORITIZATION.post(url, body, {
    headers: { "Content-Type": "application/json" },
  })
  return result
}

export async function postSetRedButtonStatus(token, client, body) {
  tokenAuth(token, "PRIORITIZATION")
  const url = `${client}/prioritization/set_red_button_status`
  let result = await axiosClientPRIORITIZATION.post(url, body, {
    headers: { "Content-Type": "application/json" },
  })
  return result
}

export async function postGetRedButtonStatus(token, client, body) {
  tokenAuth(token, "PRIORITIZATION")
  const url = `${client}/prioritization/get_red_button_status`
  let result = await axiosClientPRIORITIZATION.post(url, body, {
    headers: { "Content-Type": "application/json" },
  })
  return result
}