const tpsTranslate = {
  "CREATED": "Creada",
  "READY_TO_GO": "Lista para bajar",
  "IN_TRANSIT": "En tránsito",
  "GATEIN_COMPLETE": "Gate In Completado",
  "CLOSED": "Cerrada",
  "CANCELED": "Cancelada",
}

export default tpsTranslate
