import { useState, useContext } from 'react'
import { AuthContext } from "@Context/context/AuthContext"
import { GlobalContext } from "@Context/context/GlobalState"
import { updateOrder, actionOrder } from "@Services/orderService"
import Swal from 'sweetalert2'
import { useTranslation } from "react-i18next"

const useOrderActionsOOM = ({ afterSuccessAction, withTimeout }) => {
  const { t } = useTranslation("global")
  const { user } = useContext(AuthContext)
  const tokenService = user.user.token
  const { handleErrorSession } = useContext(GlobalContext)
  const [loadingUpdateOrderAction, setLoadingUpdateOrderAction] = useState(false);
  const [loadingUpdateMultipleOrdersAction, setLoadingUpdateMultipleOrdersAction] = useState(false);
  const [loadingOnActionOrder, setLoadingOnActionOrder] = useState(false);
  const [loadingOnActionMultipleOrders, setLoadingOnActionMultipleOrders] = useState(false);
  const anyRequestLoading = Boolean(
    loadingUpdateOrderAction ||
    loadingUpdateMultipleOrdersAction ||
    loadingOnActionOrder ||
    loadingOnActionMultipleOrders
  )

  const successAlert = (actionLoading) => {
    if (withTimeout) {
      setTimeout(() => {
        Swal.fire(
          t("Success"),
          t("done"),
          "success"
        )
        actionLoading(false)
        afterSuccessAction && afterSuccessAction()
      }, withTimeout)
    } else {
      Swal.fire(
        t("Success"),
        t("done"),
        "success"
      )
      actionLoading(false)
      afterSuccessAction && afterSuccessAction()
    }
  }

  const updateOrderAction = async (orderId, body = {}) => {
    try {
      setLoadingUpdateOrderAction(true)
      await updateOrder(
        tokenService,
        body,
        orderId
      )
      successAlert(setLoadingUpdateOrderAction)
    } catch (error) {
      setLoadingUpdateOrderAction(false)
      handleErrorSession({
        error: true,
        errorCode: error.response.status,
        messageError: error?.response?.data?.message,
      })
    }
  }

  const updateMultipleOrdersAction = async (orderIds, body = {}) => {
    try {
      if (!orderIds?.length) return null
      setLoadingUpdateMultipleOrdersAction(true)
      orderIds.map(async (ord) => {
        await updateOrder(
          tokenService,
          body,
          ord
        )
      })
      successAlert(setLoadingUpdateMultipleOrdersAction)
    } catch (error) {
      setLoadingUpdateMultipleOrdersAction(false)
      handleErrorSession({
        error: true,
        errorCode: error.response.status,
        messageError: error?.response?.data?.message,
      })
    }
  }

  const onActionOrder = async (orderId, action, body = {}) => {
    try {
      setLoadingOnActionOrder(true)
      await actionOrder(
        tokenService,
        orderId,
        action,
        body,
      )
      successAlert(setLoadingOnActionOrder)
    } catch (error) {
      setLoadingOnActionOrder(false)
      handleErrorSession({
        error: true,
        errorCode: error.response.status,
        messageError: error?.response?.data?.message,
      })
    }
  }

  const onActionMultipleOrders = async (orderIds, action, body = {}) => {
    try {
      if (!orderIds?.length) return null
      setLoadingOnActionMultipleOrders(true)
      orderIds.map(async (ord) => {
        await actionOrder(
          tokenService,
          ord,
          action,
          body,
        )
      })
      successAlert(setLoadingOnActionMultipleOrders)
    } catch (error) {
      setLoadingOnActionMultipleOrders(false)
      handleErrorSession({
        error: true,
        errorCode: error.response.status,
        messageError: error?.response?.data?.message,
      })
    }
  }

  return {
    anyRequestLoading,
    loadingUpdateOrderAction,
    loadingUpdateMultipleOrdersAction,
    loadingOnActionOrder,
    loadingOnActionMultipleOrders,
    updateOrderAction,
    updateMultipleOrdersAction,
    onActionOrder,
    onActionMultipleOrders
  }
}

export default useOrderActionsOOM