import core from "./core"
import casaideas from "./casaideas"
import logytech from "./logytech"
import shiperto from "./shiperto"
import sodimac from "./sodimac"
import tps from "./tps"

import getWebsite from "@Website"
let website = getWebsite()

const getSidebarRouteItems = () => {
  let clients = {
    core,
    casaideas,
    logytech,
    shiperto,
    sodimac,
    tps
  }
  let result = clients.hasOwnProperty(website) ? clients[website] : clients.core
  return result
}

export default getSidebarRouteItems;