import React, { useState, useEffect } from "react"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import Typography from "@material-ui/core/Typography"
import TableSortLabel from "@material-ui/core/TableSortLabel"
import useStyles from "./styles"
import _ from 'lodash'

const CommonTableHead = ({
  columns,
  initialInfo,
  info,
  setInfo,
  reload,
  withoutPadding,
}) => {
  const [activeColumn, setActiveColumn] = useState(null)
  const [filterDirection, setfilterDirection] = useState(0)
  const classes = useStyles()

  useEffect(() => {
    if (activeColumn) {
      const data = _.orderBy(
        info,
        [activeColumn],
        [filterDirection === 1 ? "asc" : "desc"]
      )
      if (!filterDirection) {
        setActiveColumn(null)
        setInfo(initialInfo)
      } else {
        setInfo(data)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeColumn, filterDirection])

  useEffect(() => reload && setActiveColumn(null), [reload])

  const handleActive = (event) => {
    const { currentTarget } = event
    setActiveColumn(currentTarget.id)
    if (filterDirection === 0 || filterDirection === 1) {
      setfilterDirection(filterDirection + 1)
    } else {
      setfilterDirection(0)
    }
  }

  return (
    <TableHead>
      <TableRow key="header" size="small">
        {columns.map((value, ind) =>
          typeof value === "object" ? (
            <TableCell
              key={ind}
              className={classes.headCell}
              padding="default"
              style={withoutPadding ? { padding: "0.2em 0.5em" } : {}}
              align="left"
            >
              {value.orderable ? (
                <TableSortLabel
                  hideSortIcon={true}
                  active={activeColumn === value.orderableField}
                  id={value.orderableField}
                  direction={filterDirection === 1 ? "asc" : "desc"}
                  onClick={handleActive}
                >
                  <Typography noWrap variant="subtitle2">
                    {value.label}
                  </Typography>
                </TableSortLabel>
              ) : (
                <Typography noWrap variant="subtitle2">
                  {value.label}
                </Typography>
              )}
            </TableCell>
          ) : (
            <TableCell
              key={ind}
              className={classes.headCell}
              padding="default"
              style={withoutPadding ? { padding: "0.2em 0.5em" } : {}}
              align="left"
            >
              <Typography noWrap variant="subtitle2">
                {value}
              </Typography>
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  )
}

export default CommonTableHead
