import { axiosClientOSRM } from "../config/axios"
import tokenAuth from "../config/token"

export async function getListSearchables(token) {
  tokenAuth(token, "OSRM")
  const url = "search?from=0&size=50"
  let result = await axiosClientOSRM.get(url, {
    headers: { "Content-Type": "application/json" },
  })
  return result
}

export async function postSearchEntity(entity, body, token) {
  tokenAuth(token, "OSRM")
  const url = `search/${entity}`
  let result = await axiosClientOSRM.post(url, body, {
    headers: { "Content-Type": "application/json" },
  })
  return result
}