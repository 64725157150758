import React, { useState, useEffect, useContext } from "react"
import Table from "@material-ui/core/Table"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import TreeViewCustomFields from "@Components/CustomTreeView"
import { getCurrentEnv } from "@Website"
import useStyles from "./styles"
import { AuthContext } from "@Context/context/AuthContext"
import { useTranslation } from "react-i18next"
import useUserPermissions from "@Hooks/useUserPermissions"
import rsc from "@Config/resources"

const ProfileBody = () => {
  const { t } = useTranslation("global")
  const classes = useStyles()
  const { user } = useContext(AuthContext)
  const [listPermissions, setListPermissions] = useState({})
  const currentEnv = getCurrentEnv()
  const { permission, checking } = useUserPermissions({
    resources: Object.values(rsc),
  })

  useEffect(() => {
    if (!checking) {
      setListPermissions(permission)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checking])

  const PermissionList = () =>
    !checking && (
      <TableRow>
        <TableCell>
          <Typography variant="body2" className={classes.labels}>
            {t("resources")}
          </Typography>
          <TreeViewCustomFields json={{ listPermissions }} />
        </TableCell>
      </TableRow>
    )

  return (
    <div>
      <TableContainer component={Paper} className={classes.mainContainer}>
        <Table className={classes.table} aria-label="simple table">
          <TableRow>
            <TableCell>
              <Typography variant="body2" className={classes.labels}>
                {t("id")}
              </Typography>
              <Typography variant="body2">{user?.user?.id}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="body2" className={classes.labels}>
                {t("name")}
              </Typography>
              <Typography variant="body2">{user?.user?.name}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="body2" className={classes.labels}>
                {t("email")}
              </Typography>
              <Typography variant="body2">{user?.user?.email}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="body2" className={classes.labels}>
                {t("role")}
              </Typography>
              <Typography variant="body2">{user?.user?.role}</Typography>
            </TableCell>
          </TableRow>
          {["dev", "uat"].includes(currentEnv) && <PermissionList />}
        </Table>
      </TableContainer>
    </div>
  )
}

export default ProfileBody
