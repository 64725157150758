import React, { useContext, useState, useEffect, Suspense } from "react"
import { AuthContext } from "@Context/context/AuthContext"
import { GlobalContext } from "@Context/context/GlobalState"
import { getCalendar } from "@Services/calendarService"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import LocalShippingIcon from "@material-ui/icons/LocalShipping"
import { useTranslation } from "react-i18next"
import useUserPermissions from "@Hooks/useUserPermissions"
import rsc from "@Config/resources"
import { useHistory } from "react-router"

import { LoadComponentByCondition } from "@Methods"
const Component = new LoadComponentByCondition("FormCalendar").render()

const EditCalendar = () => {
  const { t } = useTranslation("global")
  const [info, setInfo] = useState([])
  const [load, setLoad] = useState(true)
  const { id } = useParams()
  const { user } = useContext(AuthContext)
  const { handleRoutingBreadcrumbs, handleErrorSession } =
    useContext(GlobalContext)
  const { permission, checking } = useUserPermissions({
    resources: [rsc.CaCrudEditCalendar, rsc.CALENDAR_BUTTON_EDIT_CALENDAR],
  })
  const history = useHistory()

  useEffect(() => {
    if (!checking) {
      if (Object.values(permission).every((val) => val === false))
        return history.goBack()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checking])

  useEffect(() => {
    handleRoutingBreadcrumbs({
      titleHeader: t("edit-calendar"),
      routes: [
        { name: t("logistics"), route: "/calendar" },
        { name: t("edit-calendar"), route: `/calendar/${id}` },
      ],
      icon: <LocalShippingIcon style={{ marginRight: 5, opacity: 0.5 }} />,
    })
    getCalendarInformation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //Make the request to get the calendar information
  const getCalendarInformation = async () => {
    try {
      setLoad(true)
      const response = await getCalendar(user.user.token)
      setInfo(response.data.message)
      setLoad(false)
    } catch (error) {
      switch (error.response.status) {
        case 401:
          return handleErrorSession({
            error: true,
            errorCode: error.response.status,
            messageError: error?.response?.data?.message,
          })
        case 404:
          return onEmptyRequest()
        default:
          return handleErrorSession({
            error: true,
            errorCode: error.response.status,
            messageError: error?.response?.data?.message,
          })
      }
    }
  }
  const onEmptyRequest = () => {
    setInfo([])
    setLoad(false)
  }

  return (
    <Suspense fallback>
      <Component info={info} load={load} edit={true} />
    </Suspense>
  )
}

export default EditCalendar
