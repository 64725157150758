import { useEffect, useState, useContext } from "react"
import {
  postGetRedButtonStatus,
  postHighPriorityTruck,
  postSetRedButtonStatus,
} from "@Services/prioritizationService"
import { postSearchEntity } from "@Services/osrmServices"
import { AuthContext } from "@Context/context/AuthContext"
import { GlobalContext } from "@Context/context/GlobalState"
import { useTranslation } from "react-i18next"
import Swal from "sweetalert2"

const usePrioritizationServices = ({
  client,
  loadRedButtonStatus = false,
  afterSuccessAction,
  withTimeout,
  getTrucksAcceptOrderInfo = false,
  currentStatus
}) => {
  const { t } = useTranslation("global")
  const { user } = useContext(AuthContext)
  const tokenService = user.user.token
  const { handleErrorSession } = useContext(GlobalContext)
  const [redButtonStatus, setRedButtonStatus] = useState(false)
  const [acceptOrdersInfo, setAcceptOrdersInfo] = useState(null)
  const [loadingRedButton, setLoadingRedButton] = useState(false)
  const [loadingRequest, setLoadingRequest] = useState(false)

  useEffect(() => {
    loadRedButtonStatus && getRedButtonStatusAction()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadRedButtonStatus])

  useEffect(() => {
    getTrucksAcceptOrderInfo && getTrucksAcceptOrderInfoAction()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTrucksAcceptOrderInfo])

  const onSuccess = (actionLoading) => {
    if (withTimeout) {
      setTimeout(() => {
        Swal.fire(t("Success"), t("done"), "success")
        actionLoading(false)
        afterSuccessAction && afterSuccessAction()
      }, withTimeout)
    } else {
      Swal.fire(t("Success"), t("done"), "success")
      actionLoading(false)
      afterSuccessAction && afterSuccessAction()
    }
  }

  const getRedButtonStatusAction = async () => {
    try {
      setLoadingRedButton(true)
      const response = await postGetRedButtonStatus(tokenService, client, {
        event: {
          name: "get_red_button_status",
          data: {},
        },
      })
      const statusButtonResponse =
        response?.data.integrations[0]?.response?.status
      setRedButtonStatus(statusButtonResponse)
      setLoadingRedButton(false)
    } catch (error) {
      setLoadingRedButton(false)
      handleErrorSession({
        error: true,
        errorCode: error.response.status,
        messageError: error?.response?.data?.message,
      })
    }
  }

  const postSetRedButtonStatusAction = async (statusRedButton) => {
    try {
      setLoadingRequest(true)
      await postSetRedButtonStatus(tokenService, client, {
        event: {
          name: "set_red_button_status",
          data: {
            status: statusRedButton,
            user: user?.user?.id,
          },
        },
      })
      onSuccess(setLoadingRequest)
    } catch (error) {
      setLoadingRequest(false)
      handleErrorSession({
        error: true,
        errorCode: error.response.status,
        messageError: error?.response?.data?.message,
      })
    }
  }

  const postHighPriorityTruckAction = async (orderIds = []) => {
    try {
      if (!orderIds?.length) return null
      setLoadingRequest(true)
      await postHighPriorityTruck(tokenService, client, {
        event: {
          name: "high_priority_trucks",
          data: {
            ids: orderIds,
          },
        },
      })
      onSuccess(setLoadingRequest)
    } catch (error) {
      setLoadingRequest(false)
      handleErrorSession({
        error: true,
        errorCode: error.response.status,
        messageError: error?.response?.data?.message,
      })
    }
  }

  const getTrucksAcceptOrderInfoAction = async () => {
    try {
      setLoadingRequest(true)
      const responseContacted = await postSearchEntity(
        "orders",
        {
          size: 1,
          filters: {
            "custom.acceptOrder": "TRUE",
            "currentStatus.tag": currentStatus
          },
        },
        tokenService
      )
      const responsePending = await postSearchEntity(
        "orders",
        {
          size: 1,
          filters: {
            "custom.acceptOrder": "pending",
            "currentStatus.tag": currentStatus
          },
        },
        tokenService
      )
      setAcceptOrdersInfo({
        contactedTrucks: responseContacted?.data?.message?.total,
        pendingContactedTrucks: responsePending?.data?.message?.total,
      })
      setLoadingRequest(false)
    } catch (error) {
      setLoadingRequest(false)
      handleErrorSession({
        error: true,
        errorCode: error.response.status,
        messageError: error?.response?.data?.message,
      })
    }
  }

  return {
    redButtonStatus,
    loadingRedButton,
    loadingRequest,
    acceptOrdersInfo,
    postHighPriorityTruckAction,
    postSetRedButtonStatusAction,
  }
}

export default usePrioritizationServices
