import React, { useContext, useEffect } from "react"
import { useHistory } from "react-router-dom"
import Button from "@material-ui/core/Button"
import FormLabel from "@material-ui/core/FormLabel"
import Table from "@material-ui/core/Table"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableRow from "@material-ui/core/TableRow"
import CircularProgress from "@material-ui/core/CircularProgress"
import TableBody from "@material-ui/core/TableBody"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"
import ClearIcon from "@material-ui/icons/Clear"
import CheckIcon from "@material-ui/icons/Check"
import useStyles from "@Components/FormSources/styles"
import { useForm, Controller } from "react-hook-form"
import { GlobalContext } from "@Context/context/GlobalState"
import { schemaValidation } from "./configForm"
import { yupResolver } from "@hookform/resolvers/yup"
import { useTranslation } from "react-i18next"
import usePrioritizationServices from "@Hooks/usePrioritizationServices"
import SettingsIcon from "@material-ui/icons/Settings"

const SetRedButtonScreen = () => {
  const { handleRoutingBreadcrumbs } = useContext(GlobalContext)
  const history = useHistory()
  const classes = useStyles()
  const { control, handleSubmit, formState, watch, reset } = useForm({
    resolver: yupResolver(schemaValidation),
  })
  const values = watch()
  const { t } = useTranslation("global")
  const { redButtonStatus, loadingRedButton, postSetRedButtonStatusAction } =
    usePrioritizationServices({
      client: "tps",
      loadRedButtonStatus: true,
      afterSuccessAction: () => history.push("/orders/list/CREATED"),
    })

  useEffect(() => {
    if (!loadingRedButton) {
      reset({ redButtonStatus })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingRedButton])

  useEffect(() => {
    handleRoutingBreadcrumbs({
      titleHeader: t("red-button-status"),
      routes: [
        { name: t("settings"), route: "/settings" },
        { name: t("red-button-status"), route: "/settings/red_button/set_status" },
      ],
      icon: <SettingsIcon style={{ marginRight: 5, opacity: 0.5 }} />,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t])

  const onSubmit = async (data) => {
    postSetRedButtonStatusAction(data.redButtonStatus)
  }

  const ButtonsAction = ({ isSubmitting }) => {
    return (
      <div className={classes.inputGroupActions}>
        <Button
          type="reset"
          disabled={Boolean(isSubmitting)}
          variant="contained"
          size="small"
          className={classes.cancelButton}
          endIcon={<ClearIcon />}
          onClick={() => history.goBack()}
        >
          {t("cancel")}
        </Button>
        <Button
          type="submit"
          disabled={Boolean(isSubmitting)}
          variant="contained"
          size="small"
          className={classes.createButton}
          endIcon={<CheckIcon />}
        >
          {isSubmitting ? t("wait") : t("save")}
        </Button>
      </div>
    )
  }

  return (
    <div>
      {loadingRedButton === false ? (
        <form
          className={classes.formContainer}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={classes.contLabelSwitch}>
            <FormLabel component="legend" className={classes.labelSwitch}>
              {t("red-button-status")}
            </FormLabel>
            <FormControlLabel
              control={
                <Controller
                  name="redButtonStatus"
                  defaultValue={false}
                  control={control}
                  render={({ value, onChange }) => (
                    <Switch
                      checked={value}
                      onChange={(e, value) => onChange(value)}
                    />
                  )}
                />
              }
              label={values.redButtonStatus ? t("active") : t("inactive")}
            />
          </div>
          <ButtonsAction isSubmitting={formState.isSubmitting} />
        </form>
      ) : (
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <span className={classes.LoaderContainer}>
                    <CircularProgress />
                  </span>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  )
}

export default SetRedButtonScreen
