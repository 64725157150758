import React, { useEffect, useContext, useState, Suspense } from "react"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { GlobalContext } from "@Context/context/GlobalState"
import { getOrderDetails } from "@Services/orderService"
import { AuthContext } from "@Context/context/AuthContext"
import CardTravelIcon from "@material-ui/icons/CardTravel"
import { useTranslation } from "react-i18next"
import useUserPermissions from "@Hooks/useUserPermissions"
import rsc from "@Config/resources"
import { useHistory } from "react-router"

import { LoadComponentByCondition } from "@Methods"
const Component = new LoadComponentByCondition("DetailOrder").render()

const ShowOrderScreen = () => {
  const { id } = useParams()
  const { user } = useContext(AuthContext)
  const { handleRoutingBreadcrumbs, handleErrorSession } =
    useContext(GlobalContext)
  const [info, setInfo] = useState({})
  const [load, setLoad] = useState(true)
  const { t } = useTranslation("global")
  const { permission, checking } = useUserPermissions({
    resources: [rsc.OrListOrder, rsc.ORDER_LIST_ORDER_LIST],
  })
  const history = useHistory()

  useEffect(() => {
    if (!checking) {
      if (Object.values(permission).every((val) => val === false))
        return history.goBack()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checking])

  useEffect(() => {
    handleRoutingBreadcrumbs({
      titleHeader: t("detail-orders"),
      routes: [
        { name: t("order"), route: "/order/new" },
        { name: t("detail-orders"), route: `/order/${id}` },
      ],
      icon: <CardTravelIcon style={{ marginRight: 5, opacity: 0.5 }} />,
    })
    setLoad(true)
    loadDataOrder()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const loadDataOrder = async () => {
    try {
      let response = await getOrderDetails(user.user.token, id)
      setInfo(response.data.message)
      setLoad(false)
    } catch (error) {
      handleErrorSession({
        error: true,
        errorCode: error.response.status,
        messageError: error?.response?.data?.message,
      })
    }
  }

  return (
    <Suspense fallback>
      <Component load={load} info={info} />
    </Suspense>
  )
}

export default ShowOrderScreen
