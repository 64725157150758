import React, { useEffect, Suspense } from "react"
import useUserPermissions from "@Hooks/useUserPermissions"
import rsc from "@Config/resources"
import { useHistory } from "react-router"
import { useGetAllScheduleSettingsDetailsOLM } from "@Hooks/requests"

import { LoadComponentByCondition } from "@Methods"
const Component = new LoadComponentByCondition("FormItems").render()

const CreateItem = () => {
  const { permission, checking } = useUserPermissions({
    resources: [rsc.ItCrudCreateItem, rsc.ITEM_BUTTON_CREATE_ITEM],
  })
  const history = useHistory()
  const { deliveryTypes, loadSchedule } = useGetAllScheduleSettingsDetailsOLM()

  useEffect(() => {
    if (!checking) {
      if (Object.values(permission).every((val) => val === false))
        return history.goBack()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checking])

  return (
    <Suspense fallback>
      <Component load={loadSchedule} deliveryTypes={deliveryTypes} />
    </Suspense>
  )
}

export default CreateItem
