import React, { useState, useEffect, useContext } from "react"
import { GlobalContext } from "@Context/context/GlobalState"
import { useTranslation } from "react-i18next"
import esLocale from "date-fns/locale/es"

// Material UI
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns"
import { MobileDatePicker, LocalizationProvider } from "@material-ui/pickers"
import { makeStyles } from "@material-ui/core/styles"
import FormGroup from "@material-ui/core/FormGroup"
import TextField from "@material-ui/core/TextField"

const useStyles = makeStyles(() => ({
  textfieldsContainer: {
    display: "flex",
    flexDirection: "row",
    padding: 10,
    gap: 10,
  },
  containerError: {
    paddingLeft: 10,
    paddingBottom: 5,
    color: "red",
  },
}))

const FilterDateRangePicker = ({ dateValue, onChangeDate }) => {
  const { t } = useTranslation("global")
  const classes = useStyles()
  const [initialDate, setInitialDate] = useState(new Date())
  const [finalDate, setFinalDate] = useState(new Date())
  const [isInvalidDate, setIsInvalidDate] = useState(false)
  const { breakpoints } = useContext(GlobalContext)

  useEffect(() => {
    if (dateValue) {
      setInitialDate(dateValue[0] || new Date())
      setFinalDate(dateValue[1] || new Date())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    onChangeDate([initialDate, finalDate])
    setIsInvalidDate(Boolean(new Date(finalDate) < new Date(initialDate)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDate, finalDate])

  return (
    <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale}>
      <FormGroup
        style={
          breakpoints.sm
            ? { flexDirection: "column", gap: 0 }
            : { flexDirection: "row" }
        }
        className={classes.textfieldsContainer}
      >
        <MobileDatePicker
          label={t("initial-date")}
          value={initialDate}
          onChange={(newValue) => setInitialDate(newValue)}
          renderInput={(props) => <TextField {...props} />}
          cancelText={t("cancel")}
        />
        <p>{t("until")}</p>
        <MobileDatePicker
          label={t("final-date")}
          value={finalDate}
          onChange={(newValue) => setFinalDate(newValue)}
          renderInput={(props) => <TextField {...props} />}
          cancelText={t("cancel")}
        />
      </FormGroup>
      {isInvalidDate && <div className={classes.containerError}>
        <span>** {t("check-entered-values")}</span>
      </div>}
    </LocalizationProvider>
  )
}

export default FilterDateRangePicker
