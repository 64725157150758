import React, { useContext } from "react"
import { useHistory } from "react-router-dom"
import clsx from "clsx"
import Drawer from "@material-ui/core/Drawer"
import Divider from "@material-ui/core/Divider"
import SettingsIcon from "@material-ui/icons/Settings"
import PersonIcon from "@material-ui/icons/Person"
import { GlobalContext } from "@Context/context/GlobalState"
import useStyles from "./styles"
import logoOmnix from "@Img/omnix.png"
import ListItems from "./ListItems"
import SidebarNavigation from "./SidebarNavigation"
import MenuIcon from "@material-ui/icons/Menu"
import IconButton from "@material-ui/core/IconButton"
import ButtonSidebarNav, { SignOutButton } from '@Components/Shared/ButtonSidebarNav';

const Sidebar = ({ width }) => {
  const history = useHistory()
  const classes = useStyles()
  const { openDrawer, handleDrawer } = useContext(GlobalContext)

  if (width === true) {
    return (
      <Drawer
        variant="temporary"
        classes={{
          paper: clsx(classes.drawerPaperMobile),
        }}
        open={openDrawer}
        onClose={() => handleDrawer}
        anchor="left"
      >
        <div className={classes.toolbarIconMobile}>
          <IconButton onClick={handleDrawer}>
            <MenuIcon />
          </IconButton>
          <img src={logoOmnix} alt="logoOmnix" />
          <IconButton onClick={() => history.push("/profile")}>
            <PersonIcon />
          </IconButton>
        </div>
        <Divider />
        <div className={classes.listItemsContainer}>
          <ListItems />
          <div>
            <ButtonSidebarNav
              name="settings"
              route="/settings"
              icon={SettingsIcon}
            />
            <SignOutButton />
          </div>
        </div>
      </Drawer>
    )
  } else {
    return (
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(
            classes.drawerPaper,
            !openDrawer && classes.drawerPaperClose
          ),
        }}
        open={openDrawer}
      >
        <div className={classes.toolbarIcon}>
          <img src={logoOmnix} alt="logoOmnix" />
        </div>
        <Divider />
        {openDrawer && <SidebarNavigation />}
        <Divider />
        <div className={classes.listItemsContainer}>
          <ListItems />
          <div>
            <ButtonSidebarNav
              name="settings"
              route="/settings"
              icon={SettingsIcon}
            />
            <SignOutButton />
          </div>
        </div>
      </Drawer>
    )
  }
}

export default Sidebar
