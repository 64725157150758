import HomeIcon from "@material-ui/icons/Home"
import CardTravelIcon from "@material-ui/icons/CardTravel"
import rsc from "@Config/resources"

const tps = [
  {
    name: "home",
    type: "simple",
    icon: HomeIcon,
    redirect: "/dashboard",
    permanent: true,
    envs: ["dev", "uat", "prod"],
  },
  // ---
  {
    name: "orders",
    type: "collapse",
    icon: CardTravelIcon,
    permissions: [rsc.ORDER_BUTTON_GROUP_SIDEBAR],
    envs: ["dev", "uat", "prod"],
    subItems: [
      {
        name: "orders-list-created",
        redirect: "/orders/list/CREATED",
        permissions: [rsc.ORDER_PAGE_LOGISTIC],
        envs: ["dev", "uat", "prod"]
      },
      {
        name: "orders-list-ready-to-go",
        redirect: "/orders/list/READY_TO_GO",
        permissions: [rsc.ORDER_PAGE_OPERATOR],
        envs: ["dev", "uat", "prod"]
      },
      {
        name: "orders-list-other",
        redirect: "/orders/list/OTHER",
        permissions: [rsc.ORDER_BUTTON_GROUP_SIDEBAR],
        envs: ["dev", "uat", "prod"]
      },
    ],
  },
]

export default tps