import React, { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { GlobalContext } from "@Context/context/GlobalState"
import { AuthContext } from "@Context/context/AuthContext"
import SettingsIcon from "@material-ui/icons/Settings"
import { useTranslation } from "react-i18next"
import { getSequenceConfig } from "@Services/orderService"
import FormIncrementalOmnixId from "@Components/FormIncrementalOmnixId"
import useUserPermissions from "@Hooks/useUserPermissions"
import rsc from "@Config/resources"

const NewIncrementalOmnixIdScreen = () => {
  const { handleRoutingBreadcrumbs, handleErrorSession } =
    useContext(GlobalContext)
  const { user } = useContext(AuthContext)
  const history = useHistory()
  const [load, setLoad] = useState(true)
  const { t } = useTranslation("global")
  const { permission, checking } = useUserPermissions({
    resources: [rsc.Setting, rsc.SETTING_BUTTON_SET_INCREMENTAL_OMNIX_ID],
  })

  useEffect(() => {
    if (!checking) {
      if (Object.values(permission).every((val) => val === false))
        return history.goBack()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checking])

  useEffect(() => {
    handleRoutingBreadcrumbs({
      titleHeader: t("incrementalOmnixId-configured-correctly"),
      routes: [
        { name: t("settings"), route: "/settings" },
        {
          name: t("incrementalOmnixId-configured-correctly"),
          route: "/settings/incremental_omnix_id/new",
        },
      ],
      icon: <SettingsIcon style={{ marginRight: 5, opacity: 0.5 }} />,
    })
    getIncrementalOmnix()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t])

  const getIncrementalOmnix = async () => {
    try {
      await getSequenceConfig(user.user.token)
      history.push("/settings/incremental_omnix_id")
    } catch (error) {
      if (error.response.status === 404) {
        return setLoad(false)
      } else {
        return handleErrorSession({
          error: true,
          errorCode: error.response.status,
        })
      }
    }
  }

  return <FormIncrementalOmnixId load={load} />
}

export default NewIncrementalOmnixIdScreen
