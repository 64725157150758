import core from "./core"
import tps from "./tps"

import getWebsite from "@Website"
let website = getWebsite()

const getRoutes = () => {
  let clients = {
    core,
    tps
  }
  let result = clients.hasOwnProperty(website) ? clients[website] : clients.core
  return result
}

export default getRoutes;