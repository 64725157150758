/* eslint-disable */
import { _env } from "@Config/"

/**
 * Get information from current website
 *
 * @version 1.0.1
 * @author Naldo Duran <naldorck@gmail.com>
 *
 */
export default class Website {
  constructor() {
    this._href = window.location.href.replace(/^https?:\/\//, '');
    this._process = process.env
    this.environments = _env.env
    this.websites = _env.clients
  }

  /**
   * Manually configure the environment, both the client and the type of environment
   *
   * @version 1.0.1
   * @author Naldo Duran <naldorck@gmail.com>
   * @param {Array<string>} arrKeys - Can be the type of environment or the type of website
   * @param {string} manualKey - Set manually the type of environment or the type of website 
   * 
   * @returns {boolean|string}
   */
  setManuallyKey(arrKeys, manualKey) {
    if (manualKey && this._href.includes(this.websites[0])) {
      if (arrKeys.indexOf(manualKey) == -1) {
        throw new Error("Website or environment doesn't exist!")
        return
      }
      return manualKey
    }
    return false
  }

  /**
   * Get the current website, you can set it manually
   *
   * @version 1.0.1
   * @author Naldo Duran <naldorck@gmail.com>
   * @param {string} manualKey - Set manually the type of website 
   * 
   * @returns {string}
   */
  getCurrentWebsite(manualKey = false) {
    let currentWebsite = this.websites[1]
    let canManually = this.setManuallyKey(this.websites, manualKey)

    if (!!canManually) {
      return canManually
    }

    if (
      !this._href.includes(this.websites[0]) &&
      !this._href.includes(this.websites[1])
    ) {
      this.websites.forEach((website) => {
        if (this._href.includes(website)) {
          currentWebsite = website
        }
      })
    }

    return currentWebsite
  }

   /**
   * Get the current environment, you can set it manually
   *
   * @version 1.0.1
   * @author Naldo Duran <naldorck@gmail.com>
   * @param {string} manualKey - Set manually the type of website 
   * 
   * @returns {string}
   */
  getCurrentEnv(manualKey = false) {
    const isLocal = this._href.includes("localhost")
    const isDev = this._href.includes(this.environments[0])
    const isUat = this._href.includes(this.environments[1])
    let canManually = this.setManuallyKey(this.environments, manualKey)

    if (!!canManually) {
      return canManually
    }

    if (isDev || isLocal) {
      return this.environments[0]
    } else if (isUat) {
      return this.environments[1]
    } else {
      return this.environments[2]
    }
  }
}
