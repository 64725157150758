import { GlobalContext } from "@Context/context/GlobalState"
import React, { useContext, useEffect, Suspense } from "react"
import SettingsIcon from "@material-ui/icons/Settings"
import { useTranslation } from "react-i18next"

import { LoadComponentByCondition } from "@Methods"
const Component = new LoadComponentByCondition("GeneralSettingsBody").render()

const GeneralSettingsScreen = () => {
  const { handleRoutingBreadcrumbs } = useContext(GlobalContext)
  const { t } = useTranslation("global")

  useEffect(() => {
    handleRoutingBreadcrumbs({
      titleHeader: t("settings"),
      routes: [{ name: t("settings"), route: "/settings" }],
      icon: <SettingsIcon style={{ marginRight: 5, opacity: 0.5 }} />,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t])
  
  return (
    <Suspense fallback>
      <Component />
    </Suspense>
  )
}

export default GeneralSettingsScreen
