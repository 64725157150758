import React, { useEffect, useContext, Suspense } from "react"
import { GlobalContext } from "@Context/context/GlobalState"
import CardTravelIcon from "@material-ui/icons/CardTravel"
import { useTranslation } from "react-i18next"
import useUserPermissions from "@Hooks/useUserPermissions"
import rsc from "@Config/resources"
import { useHistory } from "react-router"

import { LoadComponentByCondition } from "@Methods"
const Component = new LoadComponentByCondition("TableCompareAcceptSG").render()

const ListShippingGroupAccept = () => {
  const { handleRoutingBreadcrumbs } = useContext(GlobalContext)
  const { t } = useTranslation("global")
  const {permission, checking} = useUserPermissions({resources: [
    rsc.UaListSg
  ]})
  const history = useHistory()

  useEffect(() => {
    if (!checking) {
      if(Object.values(permission).every((val) => val === false)) return history.goBack()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checking])

  useEffect(() => {
    handleRoutingBreadcrumbs({
      titleHeader: t("detail-units-accepted"),
      routes: [
        { name: t("order"), route: "/shipping_groups_accept" },
        { name: t("detail-units-accepted"), route: "/shipping_groups_accept" },
      ],
      icon: <CardTravelIcon style={{ marginRight: 5, opacity: 0.5 }} />,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Suspense fallback>
      <Component />
    </Suspense>
  )
}

export default ListShippingGroupAccept
