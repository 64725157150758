import React, { useEffect, useState } from "react"
import Popover from "@material-ui/core/Popover"
import TextField from "@material-ui/core/TextField"
import { useTranslation } from "react-i18next"
import FilterDateRangePicker from "@Components/Shared/FilterDateRangePicker"

function DatePickerTextField({ date, setDate }) {
  const [openFilters, setOpenFilters] = useState(false)
  const [anchorEl, setaAchorEl] = useState(null)
  const [showDate, setShowDate] = useState("")
  const { t } = useTranslation("global")
  useEffect(() => {
    setShowDate(getStringDate(date))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date])

  const openPopOverChip = (event) => {
    setOpenFilters(true)
    setaAchorEl(event.currentTarget)
  }

  const closeModal = () => {
    setOpenFilters(false)
    setaAchorEl(null)
  }

  const getStringDate = (value) => {
    if (value[0] === null && value[1] === null) {
      return ""
    }
    if (value[0] !== null && value[1] === null) {
      return buildFormatDate(value[0])
    }
    if (value[0] !== null && value[1] !== null) {
      return `${buildFormatDate(value[0])} - ${buildFormatDate(value[1])}`
    }
  }
  const buildFormatDate = (date) => {
    const day = ("0" + date.getDate()).slice(-2)
    const month = ("0" + (date.getMonth() + 1)).slice(-2)
    const year = date.getFullYear()
    return `${day}/${month}/${year}`
  }
  return (
    <div>
      <Popover
        id="filter-date"
        open={openFilters}
        anchorEl={anchorEl}
        onClose={() => closeModal()}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <FilterDateRangePicker dateValue={date} onChangeDate={setDate} />
      </Popover>
      <TextField
        onClick={openPopOverChip}
        fullWidth
        label={t("date")}
        variant="outlined"
        value={showDate}
      ></TextField>
    </div>
  )
}

export default DatePickerTextField
