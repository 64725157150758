import axiosClient, {
  axiosClientOIM,
  axiosClientOOM,
  axiosClientOLM,
  axiosClientDOWNLOAD,
  axiosClientOSRM,
  axiosClientPRIORITIZATION
} from "./axios"

const tokenAuth = (token, clientType) => {
  let client

  if (clientType === "OIM") {
    client = axiosClientOIM
  } else if (clientType === "OOM") {
    client = axiosClientOOM
  } else if (clientType === "OLM") {
    client = axiosClientOLM
  } else if (clientType === "DOWNLOAD") {
    client = axiosClientDOWNLOAD
	} else if (clientType === "OSRM") {
    client = axiosClientOSRM
  } else if (clientType === "PRIORITIZATION") {
    client = axiosClientPRIORITIZATION
  } else {
    client = axiosClient
  }

  if (token) {
    client.defaults.headers.common["Authorization"] = token
  } else {
    delete client.defaults.headers.common["Authorization"]
  }
}

export default tokenAuth
