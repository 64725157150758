import React, { useEffect, useContext, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import Collapse from "@material-ui/core/Collapse"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import { GlobalContext } from "@Context/context/GlobalState"
import { useTranslation } from "react-i18next"
import ButtonSidebarNav from "@Components/Shared/ButtonSidebarNav"
import getSidebarRouteItems from "@Website/getSidebarRouteItems"
import { getCurrentEnv } from "@Website"
import useUserPermissions from "@Hooks/useUserPermissions"

const CollapseRoutesGroup = (props) => {
  const {
    name,
    icon: Icon,
    permissions: prms,
    subItems,
    envs,
    currentEnv,
    openDrawer,
    handleDrawer,
  } = props
  const { t } = useTranslation("global")
  const [expand, setExpand] = useState(false)
  const [enableByPermission, setEnableByPermission] = useState(false)
  const { permission, checking } = useUserPermissions({ resources: prms })

  useEffect(() => {
    if (!checking) {
      setEnableByPermission(prms.some(prm => permission[prm]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checking])

  useEffect(() => {
    if (!openDrawer && expand) {
      setExpand(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDrawer])

  useEffect(() => {
    if (expand && !openDrawer) {
      handleDrawer()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expand])

  if (!envs.includes(currentEnv)) return null
  if (!enableByPermission) return null
  return (
    <>
      <ListItem button onClick={() => setExpand(!expand)}>
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText primary={t(name)} />
        {expand ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={expand} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {(subItems || []).map((item, i) => {
            if (!item.envs.includes(currentEnv)) return null
            return (
              <SubItemCollapse
                key={i}
                item={item}
              />
            )
          })}
        </List>
      </Collapse>
    </>
  )
}

const SubItemCollapse = ({item}) => {
  const {name, redirect, permissions: prms} = item
  const [enableByPermission, setEnableByPermission] = useState(false)
  const { permission, checking } = useUserPermissions({ resources: prms })

  useEffect(() => {
    if (!checking) {
      setEnableByPermission(prms.some(prm => permission[prm]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checking])

  if (!enableByPermission) return null
  return <ButtonSidebarNav name={name} route={redirect} />
}

const SimpleRoute = (props) => {
  const {
    name,
    redirect,
    icon: Icon,
    permissions: prms,
    envs,
    permanent,
    currentEnv,
  } = props
  const [enableByPermission, setEnableByPermission] = useState(false)
  const { permission, checking } = useUserPermissions({ resources: prms })

  useEffect(() => {
    if (!checking) {
      setEnableByPermission(prms.some(prm => permission[prm]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checking])

  if (!permanent) {
    if (!envs.includes(currentEnv)) return null
    if (!enableByPermission) return null
  }
  return (
    <>
      <ButtonSidebarNav route={redirect} name={name} icon={Icon} />
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}))

const ListItems = () => {
  const { openDrawer, handleDrawer } = useContext(GlobalContext)
  const classes = useStyles()
  const currentEnv = getCurrentEnv()
  const routes = getSidebarRouteItems()

  return (
    <List component="nav" className={classes.root}>
      {routes.map((route, i) => {
        if (route.type === "simple")
          return <SimpleRoute key={i} {...route} currentEnv={currentEnv} />
        if (route.type === "collapse")
          return (
            <CollapseRoutesGroup
              key={i}
              {...route}
              openDrawer={openDrawer}
              handleDrawer={handleDrawer}
              currentEnv={currentEnv}
            />
          )
        return null
      })}
    </List>
  )
}

export default ListItems
