const casaideas = {
  // Without Sgs
  RESERVED: {
    HD: {
      order: "Creada"
    },
    SP: {
      order: "Creada"
    },
    RT: {
      order: "Creada"
    }
  },
  PENDING_PREPARATION: {
    HD: {
      order: "Ingresada",
    },
    SP: {
      order: "Ingresada",
    },
    RT: {
      order: "Preparación pendiente",
    }
  },
  // With Sgs
  AWAITING_STORE_CONFIRMATION: {
    HD: {
      sg: "Ingresada",
    },
    SP: {
      sg: "Ingresada",
    },
    RT: {
      sg: "Ingresada",
    }
  },
  PENDING_PACKAGE: {
    HD: {
      sg: "Empacado"
    },
    SP: {
      sg: "Empacado"
    },
    RT: {
      sg: "Empacado"
    }
  },
  IN_SOURCE_PREPARATION: {
    SP: {
      order: "En preparación de productos",
      sg: "En preparación de productos"
    },
  },
  IN_TRANSIT: {
    HD: {
      order: "En ruta",
      sg: "En ruta"
    },
    RT: {
      order: "Devolución en ruta",
      sg: "Devolución en ruta"
    }
  },
  READY_FOR_PICKUP: {
    SP: {
      order: "Retirado por el cliente",
      sg: "Retirado por el cliente"
    },
  },
}

export default casaideas;