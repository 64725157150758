import React, { useEffect, useContext, useState, Suspense } from "react"
import { GlobalContext } from "@Context/context/GlobalState"
import LocalShippingIcon from "@material-ui/icons/LocalShipping"
import { getCourierIdEdit } from "@Services/courierService"
import { useParams } from "react-router-dom"
import { AuthContext } from "@Context/context/AuthContext"
import { useTranslation } from "react-i18next"
import useUserPermissions from "@Hooks/useUserPermissions"
import rsc from "@Config/resources"
import { useHistory } from "react-router"

import { LoadComponentByCondition } from "@Methods"
const Component = new LoadComponentByCondition("FormCourier").render()

const EditCourierScreen = () => {
  const { t } = useTranslation("global")
  const { handleRoutingBreadcrumbs, handleErrorSession } =
    useContext(GlobalContext)
  const { courierId } = useParams()
  const { user } = useContext(AuthContext)
  const [load, setLoad] = useState(true)
  const [info, setInfo] = useState(null)
  const { permission, checking } = useUserPermissions({
    resources: [rsc.CourCrudEditCourier, rsc.COURIER_BUTTON_EDIT_COURIER],
  })
  const history = useHistory()

  useEffect(() => {
    if (!checking) {
      if (Object.values(permission).every((val) => val === false))
        return history.goBack()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checking])

  useEffect(() => {
    handleRoutingBreadcrumbs({
      titleHeader: t("edit-courier"),
      routes: [
        { name: t("logistics"), route: "/couries" },
        { name: t("edit-courier"), route: "/couriers/edit/" + courierId },
      ],
      icon: <LocalShippingIcon style={{ marginRight: 5, opacity: 0.5 }} />,
    })
    getCourier()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getCourier = async () => {
    setLoad(true)
    try {
      const response = await getCourierIdEdit(user.user.token, courierId)
      setInfo(response.data.message)
      setLoad(false)
    } catch (error) {
      handleErrorSession({
        error: true,
        errorCode: error.response.status,
      })
    }
  }

  return (
    <Suspense fallback>
      <Component info={info} edit={true} load={load} />
    </Suspense>
  )
}

export default EditCourierScreen
