import React, { useEffect, useContext, useState, Suspense } from "react"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import CardTravelIcon from "@material-ui/icons/CardTravel"
import { GlobalContext } from "@Context/context/GlobalState"
import { AuthContext } from "@Context/context/AuthContext"
import { gettingShippingGroupTable } from "@Services/shippingGroupService"
import { getOrderDetails } from "@Services/orderService"
import Swal from "sweetalert2"
import { useTranslation } from "react-i18next"
import useUserPermissions from "@Hooks/useUserPermissions"
import rsc from "@Config/resources"
import { useHistory } from "react-router"

import { LoadComponentByCondition } from "@Methods"
const Component = new LoadComponentByCondition("FormReScheduleSG").render()

const rescheduleValidStates = [
  "AWAITING_STORE_CONFIRMATION",
  "PENDING_PACKAGE",
  "AWAITING_DELIVERY",
  "REJECTED",
  "RETURN_IN_REVIEW",
  "RETURNED",
]
const shippingTypeValidStates = ["HD", "SP", "RT"]

const RescheduleShippingGroupScreen = () => {
  const { t } = useTranslation("global")
  const { id } = useParams()
  const { user } = useContext(AuthContext)
  const { handleRoutingBreadcrumbs, handleErrorSession } =
    useContext(GlobalContext)
  const [order, setOrder] = useState({})
  const [sg, setSg] = useState({})
  const [load, setLoad] = useState(true)
  const { permission, checking } = useUserPermissions({
    resources: [rsc.SgActionReschedule, rsc.SG_BUTTON_RESCHEDULE_SG],
  })
  const history = useHistory()

  useEffect(() => {
    if (!checking) {
      if (Object.values(permission).every((val) => val === false))
        return history.goBack()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checking])

  useEffect(() => {
    handleRoutingBreadcrumbs({
      titleHeader: `${t("reagend")} ${t("shipping-groups")}`,
      routes: [
        { name: t("order"), route: "/shipping_groups" },
        {
          name: `${t("reagend")} ${t("shipping-groups")}`,
          route: `/shipping_groups/${id}/reschedule`,
        },
      ],
      icon: <CardTravelIcon style={{ marginRight: 5, opacity: 0.5 }} />,
    })
    getSGAction()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (Object.keys(sg).length > 0 && Object.keys(order).length > 0) {
      setSg({ ...sg, order })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order])

  const getSGAction = async () => {
    try {
      let response = await gettingShippingGroupTable(
        user.user.token,
        {
          skipRecords: 0,
          maxRecords: 1,
          filter: { id },
        },
        "all"
      )
      let sgResult = response.data?.message?.records[0]
      if (
        rescheduleValidStates.includes(sgResult.currentStatus?.tag) &&
        shippingTypeValidStates.includes(sgResult.shippingType)
      ) {
        setSg(sgResult)
        getOrderAction(sgResult.orderId)
      } else {
        history.goBack()
      }
    } catch (error) {
      handleErrorSession({
        error: true,
        errorCode: error.response.status,
        messageError: error?.response?.data?.message,
      })
    }
  }

  const getOrderAction = async (orderId) => {
    try {
      let response = await getOrderDetails(user.user.token, orderId)
      setOrder(response.data.message)
      if (response?.data?.message?.currentStatus?.tag === "CLOSED") {
        Swal.fire({
          title: t("oops"),
          text: `${t("the-order-associated-with-this")} ${t(
            "shipping-groups"
          )}, ${t("it-was-already-closed")}!`,
          confirmButtonColor: "#FD0049",
        })
        history.goBack()
      }
      setOrder(response?.data?.message)
      setLoad(false)
    } catch (error) {
      handleErrorSession({
        error: true,
        errorCode: error.response.status,
        messageError: error?.response?.data?.message,
      })
    }
  }

  return (
    <Suspense fallback>
      <Component load={load} sg={sg} />
    </Suspense>
  )
}

export default RescheduleShippingGroupScreen
