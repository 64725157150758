import React, { useEffect, useContext, Suspense } from "react"
import { GlobalContext } from "@Context/context/GlobalState"
import CardTravelIcon from "@material-ui/icons/CardTravel"
import { useTranslation } from "react-i18next"
import useUserPermissions from "@Hooks/useUserPermissions"
import rsc from "@Config/resources"
import { useHistory } from "react-router"

import { LoadComponentByCondition } from "@Methods"
const Component = new LoadComponentByCondition("FormOrders").render()

const NewOrderScreen = () => {
  const { handleRoutingBreadcrumbs } = useContext(GlobalContext)
  const { t } = useTranslation("global")
  const { permission, checking } = useUserPermissions({
    resources: [rsc.OrCrudCreateSimpleOrder, rsc.ORDER_BUTTON_CREATE_ORDER],
  })
  const history = useHistory()

  useEffect(() => {
    if (!checking) {
      if (Object.values(permission).every((val) => val === false))
        return history.goBack()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checking])

  useEffect(() => {
    handleRoutingBreadcrumbs({
      titleHeader: t("create-simple-order"),
      routes: [
        { name: t("orders"), route: "/orders" },
        { name: t("create-simple-order"), route: "/orders/simple/new" },
      ],
      icon: <CardTravelIcon style={{ marginRight: 5, opacity: 0.5 }} />,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Suspense fallback>
      <Component load={false} />
    </Suspense>
  )
}

export default NewOrderScreen
