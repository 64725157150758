import { makeStyles } from "@material-ui/core/styles"

export default makeStyles((theme) => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "100%",
    background: "#fff",
    padding: theme.spacing(1.5),
    boxShadow:
      "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
    borderRadius: 5,
  },
  inputGroupActions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    "&:first-child": {
      marginTop: 10,
    },
    "&:last-child": {
      marginTop: 10,
      marginBottom: 10,
    },
  },
  cancelButton: {
    background: "#fff",
    color: "#FD0049",
    border: "solid 1px #FD0049",
    textTransform: "uppercase",
    "&:hover, &:focus": {
      backgroundColor: "#FAFAFA",
    },
    margin: 5,
  },
  createButton: {
    background: "#FD0049",
    color: "#fff",
    textTransform: "uppercase",
    border: "solid 1px #FD0049",
    "&:hover, &:focus": {
      backgroundColor: "#FD0000",
      color: "#fff",
    },
    margin: 5,
  },
  addButtonSlot: {
    background: "#FD0049",
    color: "#fff",
    textTransform: "uppercase",
    border: "solid 1px #FD0049",
    "&:hover, &:focus, &:disabled": {
      backgroundColor: "#FD0000",
      color: "#fff",
    },
    margin: "15px 5px",
  },
  deleteButtonSlot: {
    background: "#fff",
    color: "#FD0049",
    textTransform: "uppercase",
    "&:hover, &:focus": {
      backgroundColor: "#FFFFFF",
    },
    margin: 5,
  },
  inputGroup: {
    maxWidth: "100%",
    height: "auto",
    display: "flex",
    // justifyContent: 'space-between',
    // flexWrap: 'wrap',
  },
  input: {
    flexGrow: 1,
    margin: "10px 5px",
  },
  contLabelSwitch: {
    margin: "20px",
  },
  labelSwitch: {
    fontWeight: 500,
    marginBottom: 5,
    fontSize: 14,
    textTransform: "uppercase",
  },
  labelGroup: {
    fontSize: 14,
    fontWeight: 500,
    margin: "15px 20px",
    textTransform: "uppercase",
  },
  logisticsAttributesContainer: {
    width: "100%",
    display: "flex",
  },
  LoaderContainer: {
    minHeight: "80vh",
    display: "flex",
    minWidth: "10vw",
    justifyContent: "center",
    alignItems: "center",
  },
  containerMainTimePickers: {
    maxWidth: "100%",
    height: "auto",
    display: "flex",
    justifyContent: "space-around",
  },
  containerTimePickers: {
    maxWidth: "100%",
    height: "auto",
    display: "flex",
    justifyContent: "space-around",
    color: "#000",
    margin: "10px 0",
  },
}))
