/* eslint-disable */
import { _env } from "@Config/"

export default class AxiosMethods {
  constructor() {
    this._href = window.location.href.replace(/^https?:\/\//, '');
    this.websites = _env.clients
    this.environments = _env.env
    this._process = process.env
  }

  setManuallyKey(arrKeys, manualKey) {
    if (manualKey && this._href.includes(this.websites[0])) {
      if (arrKeys.indexOf(manualKey) == -1) {
        throw new Error("Website or environment doesn't exist!")
        return
      }
      return manualKey.toUpperCase()
    }
    return false
  }

  getCurrentWebsite(manualWebsite = false) {
    let currentWebsite = this.websites[1].toUpperCase()
    let canManually = this.setManuallyKey(this.websites, manualWebsite)

    if (!!canManually) {
      return canManually
    }

    if (
      !this._href.includes(this.websites[0]) &&
      !this._href.includes(this.websites[1])
    ) {
      this.websites.forEach((website) => {
        if (this._href.includes(website)) {
          currentWebsite = website.toUpperCase()
        }
      })
    }

    return currentWebsite
  }

  getCurrentEnv(manualEnv = false) {
    const isLocal = this._href.includes("localhost")
    const isDev = this._href.includes(this.environments[0])
    const isUat = this._href.includes(this.environments[1])
    let canManually = this.setManuallyKey(this.environments, manualEnv)

    if (!!canManually) {
      return canManually
    }

    if (isDev || isLocal) {
      return this.environments[0].toUpperCase()
    } else if (isUat) {
      return this.environments[1].toUpperCase()
    } else {
      return this.environments[2].toUpperCase()
    }
  }

  buildUrl(group) {
    const env = this.getCurrentEnv()
    const currentWebsite = this.getCurrentWebsite()
    if (group !== "DOWNLOAD") {
      const urlBase =
        this._process[`REACT_APP_BASE_${env}_URL_${currentWebsite}`]
      const urlGroup = this._process[`REACT_APP_API_URL_${group}`]
      return `${urlBase}-${urlGroup}`
    } else {
      const url = `${
        this._process[`REACT_APP_BASE_${env}_URL_DOWNLOAD`]
      }/clients/${currentWebsite.toLowerCase()}`
      return url
    }
  }
}
